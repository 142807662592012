import { NextSeo } from 'next-seo'
import NextLink from 'next/link'
import styled from 'styled-components'

import { Tag } from 'components/ui'
import { ButtonLink } from 'components/ui/deprecated/ButtonLink'
import { Text } from 'components/ui/deprecated/Text'
import { useDataContext } from 'lib/contexts/dataContext'
import { useTranslation } from 'lib/i18n'

import { Layout } from '../layout/Layout'

type Props = {
  statusCode?: number
  title?: string
}

// FIXME: This is used for errors other than 404, rename!
export const NotFoundPage = ({
  statusCode = 404,
  title,
}: Props): JSX.Element => {
  const { config } = useDataContext()
  const { i18n } = useTranslation()

  return (
    <Layout>
      <NextSeo
        title={title || i18n('notFound.title')}
        nofollow={true}
        noindex={true}
      />
      <Wrapper data-tfp-error={statusCode}>
        <Tag className="mb-4">{statusCode.toString()}</Tag>

        <Text as="h2" variant="title">
          {title || i18n('notFound.title')}
        </Text>
        <div className="my-8 mx-auto self-center w-fit">
          <img
            src="/assets/search-no-results.svg"
            alt={i18n('notFound.title')}
          />
        </div>
        {/* Only show link if we don't have the topnav (otherwise we can use the menu to navigate) */}
        {!config && (
          <NextLink href={'/'} passHref legacyBehavior>
            <ButtonLink href={'/'}>{i18n('notFound.goHomepage')}</ButtonLink>
          </NextLink>
        )}
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.section`
  padding: 5rem 2rem;

  text-align: center;

  max-width: 50rem;
  margin: auto;
`
