import * as Sentry from '@sentry/nextjs'
import { NextPage } from 'next'
import router from 'next/router'
import React, { useEffect } from 'react'
import { ISbStoryData } from 'storyblok-js-client'

import { NotFoundPage } from 'components/blocks/pages/NotFoundPage'
import {
  getConfigPaths,
  getInConfig,
  getLinks,
  getRegions,
  getTranslationStrings,
} from 'lib/api'
import { DataContext } from 'lib/contexts/dataContext'
import { useTranslation } from 'lib/i18n'
import { ConfigStoryblok } from 'lib/storyblok/types'
import { removeLeadingAndTrailingSlash } from 'lib/utils/string'

type Props = {
  statusCode: number
  locale: string
  messages: Record<string, string>
  config: ISbStoryData<ConfigStoryblok> | null
  regions: { code: string; name: string }[]
  isSubSite: boolean
}

const CustomError: NextPage<Props> = ({
  statusCode,
  config,
  regions,
  isSubSite,
}) => {
  const { i18n } = useTranslation()

  useEffect(() => {
    if (statusCode === 404) {
      Sentry.captureException(
        new Error(`[404] Page not found: ${router.asPath}`)
      )
    }
  }, [])

  return (
    <DataContext.Provider
      value={{
        storyId: null,
        storyUuid: null,
        regions,
        config,
        alert: null,
        avoidAlerts: false,
        floating: null,
        alternates: [],
        isSubSite,
        latestEntriesData: null,
        allEntriesData: null,
      }}
    >
      <NotFoundPage
        statusCode={statusCode}
        title={i18n(
          statusCode === 404 ? 'notFound.title' : 'somethingWentWrong'
        )}
      />
    </DataContext.Provider>
  )
}

CustomError.getInitialProps = async (contextData) => {
  const { asPath, query, res, err } = contextData

  if (res?.statusCode !== 404) {
    await Sentry.captureUnderscoreErrorException(contextData)
  }

  const statusCode = res
    ? res.statusCode
    : err && err.statusCode
    ? err.statusCode
    : 404

  const lang = Array.isArray(query.lang) ? query.lang[0] : query.lang || 'en'
  const [locale] = lang.split('-')
  const messages = await getTranslationStrings(locale)

  const links = await getLinks({
    starts_with: undefined,
    version: undefined,
    cv: undefined,
  })

  const paths = await getConfigPaths(
    links,
    removeLeadingAndTrailingSlash(asPath || '')
  )

  const config = await getInConfig<ISbStoryData<ConfigStoryblok>>(
    'config',
    paths,
    {}
  )

  const regions = await getRegions()

  return {
    locale,
    messages,
    statusCode,
    config,
    regions,
    isSubSite: paths.length > 1,
  }
}

export default CustomError
